ptz-header-drawer-bag {
  .ptz-header-drawer-bag {
    &-content {
      &-error {
        margin-top: 3.75rem;
      }
    }

    &-footer {
      border-top: $petz-border-md solid $petz-color-neutral-mid;

      &-subtotal {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: $petz-spacing-lg;
        color: $petz-color-neutral-darker-accent;
        font-weight: $petz-font-weight-bold;
        line-height: $petz-font-lineheight-md;

        &-title {
          margin: 0;
          font-size: $petz-font-size-md;
        }

        &-price {
          font-size: $petz-font-size-lg;
        }
      }

      padding: $petz-spacing-md $petz-spacing-lg $petz-spacing-lg;
    }
  }
}

@media screen and (max-width: $petz-breakpoint-xl) {
  ptz-header-drawer-bag {
    .ptz-header-drawer-bag {
      &-footer {
        &-subtotal {
          &-title {
            font-size: $petz-font-size-sm;
          }

          &-price {
            font-size: $petz-font-size-sm;
          }
        }

        padding: $petz-spacing-sm $petz-spacing-md;
      }
    }
  }
}
