import { Component, Prop, Host, h, State, Element, Listen } from '@stencil/core';
import { PTZHeaderIconDropdownTypes } from './types/ptz-header-icon-dropdown.types';
import { PTZHeaderTypes } from '../../types/ptz-header.types';
import { PTZHeaderConfig } from '../../types/ptz-header.enums';
import { isOnIframe } from '../../../../utils/utils';

@Component({
  tag: `ptz-header-icon-dropdown`,
  styleUrl: 'ptz-header-icon-dropdown.scss',
  shadow: false
})

export class PTZHeaderIconDropdown {
  /** Define a url base do ambiente */
  @Prop() baseUrl: string;

  /** Tipo de componente do icone */
  @Prop() kind: PTZHeaderIconDropdownTypes.Kind;

  /** Nome do ícone */
  @Prop() iconName: PTZHeaderIconDropdownTypes.IconName;

  /** Titulo para mostrar no hover do ícone */
  @Prop() iconTitle: string;

  /** Método de traqueamento */
  @Prop() analyticsTrackingHandler: (type: PTZHeaderTypes.AnalyticsTrackingKind, options?: PTZHeaderTypes.AnalyticsTracking) => void;

  /** Estado que controla a visualização do Dialog de Ajuda */
  @State() showDialog: boolean = false;

  @Element() el: HTMLElement;

  private handleAnalyticsTracking(link_text: string, link_url: string) {
    this.analyticsTrackingHandler(PTZHeaderConfig.AnalyticsTrackingKind.HeaderLinkClicked, {
      link_text,
      link_url,
      department: null,
      category: null,
      subcategory: null
    });
  }

  @Listen("optionSelected")
  handleOptionProfileLinks(event: CustomEvent) {
    const target = event.target as HTMLElement;
    const url = target.dataset.url;
    const value = event.detail.value;
    this.handleAnalyticsTracking(value, url);

    if (isOnIframe() && window.parent && window.parent.location) {
      window.parent.location.href = url;
    } else {
      window.location.href = url;
    }
  }

  render() {
    const ptzHeaderHelpLinks = [
      {
        title: 'Ajuda',
        url: `${this.baseUrl}/atendimento-inteligente`,
      },
      {
        title: 'Lojas',
        url: `${this.baseUrl}/nossas-lojas`,
      },
    ];

    return (
      <Host>
        <div class="ptz-header-icon-dropdown" onMouseOver={() => this.showDialog = true} onMouseOut={() => this.showDialog = false}>
          <button class={`ptz-header-icon-dropdown-button ptz-header-icon-dropdown-button-${this.kind}`}>
            <ptz-icon-notification
              noticeColor='default'
              iconColor='neutral-black'
              iconName={this.iconName}
              iconVariant='line'
            />
            <span title={this.iconTitle} class='ptz-header-icon-dropdown-button-title'>{this.iconTitle}</span>
          </button>
          <div class="ptz-header-profile-dialog-wrapper">
            <div class={{ 'ptz-header-profile-dialog': true , 'visible': this.showDialog}}>
              {ptzHeaderHelpLinks.map(item => (
                <ptz-dropdown-option key={item.title} data-url={item.url} value={item.title}></ptz-dropdown-option>
                ))}
            </div>
          </div>
        </div>
      </Host>
    );
  }
}
