ptz-header-menu-banners-drawer {
  .ptz-header-menu-banners-drawer {
    &-title {
      display: flex;
      gap: 0 1.25rem;

      p {
        padding-right: 1.125rem;
        color: $petz-color-neutral-black;
        font-weight: $petz-font-weight-extra-black;
      }
    }

    &-title, &-content {
      button {
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
        outline: inherit;
      }
    }

    &-content {
      display: flex;
      flex-wrap: wrap;
      gap: $petz-spacing-md;
      padding: 0 $petz-spacing-lg;
      justify-content: space-evenly;


      &-error {
        margin-top: 3.75rem;
      }
    }

    &-footer {
      padding: $petz-spacing-lg;
    }

    .ptz-drawer-modal-header {
      border: none;
      padding: $petz-spacing-xs $petz-spacing-lg 0;
    }

    .ptz-drawer {
       .ptz-drawer-modal {
          width: 485px;
      }
    }
  }
}
