ptz-header-icon-dropdown {
  margin-right: $petz-spacing-2xs;

  .ptz-header-icon-dropdown {
    position: relative;
    z-index: $petz-elevation-1;

    .ptz-header-icon-dropdown-button {
      border: none;
      cursor: pointer;
      position: relative;
      transition: all 300ms ease-out;
      background-color: transparent;
      height: $petz-spacing-xl;
      padding: $petz-spacing-2xs;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-title {
        opacity: 0;
        position: absolute;
        bottom: -1.25rem;
        font-weight: $petz-font-weight-regular;
        font-size: $petz-font-size-2xs;
        color: $petz-color-neutral-black;
        line-height: $petz-font-lineheight-lg;
        transition: opacity 300ms ease-out;
      }

      .ptz-notice-amount {
        line-height: 0;
      }

      &:hover {
        border-radius: $petz-rounded-max;
        background-color: rgba(red($petz-color-neutral-black), green($petz-color-neutral-black), blue($petz-color-neutral-black), $petz-opacity-xs);

        .ptz-header-icon-dropdown-button-title {
          opacity: 1;
        }
      }

    }
    .ptz-header-profile-dialog-wrapper {
      position: absolute;
      width: 100%;
      padding-top: $petz-spacing-3xs;
    }

    .ptz-header-profile-dialog {
      display: none;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      box-sizing: border-box;
      position: absolute;
      width: 250px;
      left: 0;
      top: 100%;
      box-shadow: $petz-shadow-md;
      background: $petz-color-neutral-white;
      padding: $petz-spacing-sm 0;
      border-radius: $petz-border-none $petz-border-none;
      border-radius: $petz-border-none $petz-border-none 1rem 1rem;

      &.visible{
        display: flex;
      }

      ptz-dropdown-option {
        width: 100%;

        .ptz-dropdown-option-a{
          min-height: $petz-spacing-md;
        }
      }
    }
  }
}

@media screen and (max-width: $petz-breakpoint-xl) {
  ptz-header-icon-dropdown {
    display: none;
  }
}

