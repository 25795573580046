ptz-header-menu-drawer {

    ptz-icon,
    ptz-badge {
        display: flex;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    .ptz-header-menu-drawer {
        .ptz-drawer-modal-header {
            border-bottom: none;
            padding-top: $petz-spacing-md;
            padding-bottom: $petz-spacing-md;
        }

        &-title {
            display: flex;
            align-items: center;

            &-content {
                margin-left: $petz-spacing-sm;
                font-weight: $petz-font-weight-bold;
                line-height: normal;
            }

            &-highlight {
                font-size: $petz-font-size-2xs;
                color: $petz-color-primary-brand;
                line-height: normal;
                cursor: pointer;
            }

            &>ptz-icon+span {
                margin-left: $petz-spacing-sm;
            }

            &>span+span {
                margin-left: $petz-spacing-3xs;
            }
        }

        &-list {
            &-grid {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: $petz-spacing-md;
                padding: 0 $petz-spacing-md;
                padding-top: 4.25rem;
                position: relative;
            }

            &-item {
                cursor: pointer;
            }


            &-banner {
                padding: 0 $petz-spacing-md;
                padding-top: $petz-spacing-md;

                &-grid {
                    button {
                        width: 100%;
                    }
                }

                &-image {
                    object-fit: cover;
                    height: 8.875rem;
                    width: 100%;
                    border-radius: $petz-rounded-md;
                }
            }

            &-container {
                display: flex;
                width: 100%;
                color: inherit;
                align-items: center;
                gap: $petz-spacing-2xs;
            }

            &-label,
            &-title {
                padding: $petz-spacing-xs 0;
                padding-left: $petz-spacing-md;
                display: flex;
                align-items: center;
                gap: $petz-spacing-2xs;
            }

            &-title {
                background-color: $petz-color-neutral-light;
                font-weight: $petz-font-weight-bold;

                button {
                    border: 0;
                    background: none;
                    cursor: pointer;
                    padding: 0;
                    margin: 0;
                }

                &-grid {
                    position: absolute;
                    left: 0;
                    right: 0;
                }
            }
        }

        &-content {
            height: max-content;
        }
    }

    .ptz-drawer-modal-footer {
        .drawer-footer {
            padding: $petz-spacing-md;

            ptz-button button {
                width: 100%;
            }
        }
    }

    ptz-header-error .ptz-header-error-show {
        margin: $petz-spacing-2xl $petz-spacing-lg;
    }

}
