import { Component, Event, EventEmitter, Host, Listen, Prop, State, Watch, h } from '@stencil/core';
import { PTZDrawerConfig } from '../../../ptz-drawer/types/ptz-drawer.enums';
import { PTZHeaderConfig } from '../../types/ptz-header.enums';
import { PTZHeaderTypes } from '../../types/ptz-header.types';
import { PTZHeaderMenuBannerConfig } from '../ptz-header-menu-banner/types/ptz-header-menu-banner.enums';
import { PTZHeaderMenuBannersDrawerTypes } from './types/ptz-header-menu-banners-drawer.types';
import { scrollToTop } from '../../../../utils/utils';

@Component({
  tag: 'ptz-header-menu-banners-drawer',
  styleUrl: 'ptz-header-menu-banners-drawer.scss',
  shadow: false,
})

export class PTZHeaderMenuBannersDrawer {
  /** Define a url base do ambiente */
  @Prop() baseUrl: string = '';

  /** Define o tipo de gaveta de menu */
  @Prop() kind: PTZHeaderMenuBannersDrawerTypes.Kind;

  /** Deve mostrar ou não a gaveta */
  @Prop() open: boolean = false;

  /** Indica se tem erro na chamada do menu */
  @Prop() hasError?: boolean = false;

  /** Recebe os dados de departamento de promoções do Menu */
  @Prop() salesDepartmentsData: PTZHeaderTypes.MenuDepartmentsCategories[];

  /** Recebe os dados de departamento de servços do Menu */
  @Prop() servicesDepartmentsData: PTZHeaderTypes.MenuDepartmentsCategories[];

  /** Método de traqueamento */
  @Prop() analyticsTrackingHandler: (type: PTZHeaderTypes.AnalyticsTrackingKind, options?: PTZHeaderTypes.AnalyticsTracking) => void;

  /** Guarda a lista de categoria */
  @State() categories: PTZHeaderTypes.MenuDepartmentsCategories[];

  /** Guarda a nova lista de categoria selecionada */
  @State() selectedCategories: PTZHeaderTypes.MenuDepartmentsCategories[];

  /** Guarda o novo título da gaveta */
  @State() selectedTitle: string;

  /** Guarda o título da gaveta  */
  @State() title: string;

  /** Evento para animação do drawer */
  @Event({
    eventName: 'slotDrawerChange',
    composed: true,
  }) slotDrawerChange: EventEmitter<boolean>;

  private salesTitle = 'Promoções';

  private servicesTitle = 'Tudo que o seu pet precisa em um só lugar';

  private animationTime = 300;

  private scrollableContentSelector = 'ptz-header-menu-banners-drawer .ptz-drawer-modal-content';

  private handleBackClick() {
    this.slotDrawerChange.emit(true);

    setTimeout(() => {
      this.selectedTitle = null;
      this.selectedCategories = null;
      scrollToTop(this.scrollableContentSelector);
    }, this.animationTime);
  }

  private handleSaleRedirect() {
    const url = `${this.baseUrl}/promocoes`;

    this.analyticsTrackingHandler(PTZHeaderConfig.AnalyticsTrackingKind.HeaderLinkClicked, {
      link_text: 'Ver todas as promoções',
      link_url: `${url}`,
      department: null,
      category: null,
      subcategory: null
    })

    window.location.href = url;
  }

  @Listen('toggleDrawer')
  toggleDrawerHandler() {
    scrollToTop(this.scrollableContentSelector);
  }

  @Watch('open')
  watchOpen(newValue: boolean) {
    if (!newValue) {
      this.selectedTitle = null;
      this.selectedCategories = null;
    }
  }

  private addBaseUrl(url: string): string {
    if (url?.startsWith('http')) {
      return url;
    }

    if (url?.length >= 2) {
      return `${this.baseUrl}/${url}`;
    }

    return null;
  }

  private handleMenuBannerClick(event: CustomEvent<string>, category: PTZHeaderTypes.MenuDepartmentsCategories) {
    if (category.subCategories !== undefined && category.subCategories.length > 0) {
      this.slotDrawerChange.emit(true);

      setTimeout(() => {
        this.selectedTitle = event.detail;
        this.selectedCategories = category.subCategories;
        scrollToTop(this.scrollableContentSelector);
      }, this.animationTime);
    } else {
      let url;
      const props = {
        link_text: `${category.name}`,
        image_url: `${category.urlImage}`,
      }

      switch(this.kind) {
        case PTZHeaderConfig.DrawerType.SalesMenu:
          url = `${this.baseUrl}/${category.token}`
          this.analyticsTrackingHandler(PTZHeaderConfig.AnalyticsTrackingKind.PromotionClicked, { link_url: `${url}`, ...props })
          break;
        case PTZHeaderConfig.DrawerType.ServicesMenu:
          url = `${category.token}`
          this.analyticsTrackingHandler(PTZHeaderConfig.AnalyticsTrackingKind.EcosystemLinkClicked, { link_url: `${url}`, ...props })
          break;
        default:
          break;
      }
      scrollToTop(this.scrollableContentSelector);
    }
  }

  componentDidUpdate() {
    if (this.open) {
      switch(this.kind) {
        case PTZHeaderConfig.DrawerType.SalesMenu:
          this.title = this.salesTitle;
          this.categories = this.salesDepartmentsData;
          break;
        case PTZHeaderConfig.DrawerType.ServicesMenu:
          this.title = this.servicesTitle;
          this.categories = this.servicesDepartmentsData;
          break;
        default:
          break;
      }
    }
  }

  render() {
    const content = this.selectedCategories ? this.selectedCategories : this.categories;
    
    if (this.open && this.kind === PTZHeaderConfig.DrawerType.SalesMenu && content) {
      content.forEach((item) => {
        this.analyticsTrackingHandler(PTZHeaderConfig.AnalyticsTrackingKind.PromotionViewed, {
          link_text: `${item.name}`,
          link_url: `${this.baseUrl}/${item.token}`,
          image_url: `${item.urlImage}`,
        })
      })
    }

    return (
      <Host>
        <ptz-drawer class="ptz-header-menu-banners-drawer" dynamicContentHeight open={this.open} orientation={PTZDrawerConfig.Orientation.Right} rounded={true} loading={false}>
          <div slot="drawer-header-title" class="ptz-header-menu-banners-drawer-title">
            {this.selectedCategories && (
              <button onClick={() => this.handleBackClick()}>
                <ptz-icon name="petz-chevron-left" brand="ptz" color="neutral-darker-accent" size="lg" variant="line" />
              </button>
            )}
            <p>{this.selectedTitle ? this.selectedTitle : this.title}</p>
          </div>
          <ptz-icon slot="drawer-header-close" size="lg" variant="line" name="times" color="neutral-black"></ptz-icon>
          <div slot="drawer-content" class="ptz-header-menu-banners-drawer-content">
            {this.hasError ? (
              <div class="ptz-header-menu-banners-drawer-content-error">
                <ptz-header-error show />
              </div>
            ) : (
              content?.map(category => (
                <ptz-header-menu-banner
                  key={category.id}
                  href={this.addBaseUrl(category.token)}
                  target={category.token?.startsWith('http') ? '_blank' : '_self'}
                  onMenuBannerClick={event => this.handleMenuBannerClick(event, category)}
                  label={this.kind === PTZHeaderConfig.DrawerType.ServicesMenu ? category.name : null}
                  size={this.kind === PTZHeaderConfig.DrawerType.ServicesMenu ? PTZHeaderMenuBannerConfig.Size.Small : PTZHeaderMenuBannerConfig.Size.Large}
                >
                  <img src={category.urlImage} alt={`${category.name}`} />
                </ptz-header-menu-banner>
              ))
            )}
          </div>
          {!this.hasError && this.kind === PTZHeaderConfig.DrawerType.SalesMenu &&
            <div slot='drawer-footer' class="ptz-header-menu-banners-drawer-footer">
              <ptz-button
                brand="ptz"
                id="salesAllButton"
                kind="primary"
                loading={false}
                disabled={false}
                appearance="light"
                size="lg-full-width"
                label="Ver todas as promoções"
                button-aria-label="Ver todas as promoções"
                onClick={() => this.handleSaleRedirect()}
              />
            </div>
          }
        </ptz-drawer>
      </Host>
    );
  }
}
