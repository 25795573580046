import { Component, h, Host, Prop, State } from '@stencil/core';
import { PTZHeaderTypes } from '../../types/ptz-header.types';
import { PTZHeaderDepartmentsTypes } from './types/ptz-header-departments.types';

@Component({
  tag: `ptz-header-departments`,
  styleUrl: 'ptz-header-departments.scss',
  shadow: false,
})
export class PTZHeaderDepartments {
  /** Define o conteúdo do menu do header */
  @Prop() menuData: PTZHeaderTypes.MenuDepartments[];

  /** Indica se tem erro na chamada do menu */
  @Prop() hasError?: boolean = false;

  /** Método de traqueamento */
  @Prop() analyticsTrackingHandler: (type: PTZHeaderTypes.AnalyticsTrackingKind, options?: PTZHeaderTypes.AnalyticsTracking) => void;

  @State() private newMenuData: PTZHeaderDepartmentsTypes.MenuData;
  @State() private isLoaded: boolean = false;

  private handleMenuData = () => {
    const formatMenuData = this.menuData?.map(el => {
      const othersDepartment = ['Serviços', 'Promoções'];

      return {
        label: el.name,
        hasDrawer: othersDepartment.includes(el.name),
        isAnimated: el.name !== 'Assinatura',
        iconUrl: el.urlImage || el.urlImageStatic,
        href: el.token,
        color: el.color,
        categories: el.categories
      };
    });
    this.newMenuData = formatMenuData;

    this.isLoaded = true;
  };

  componentDidUpdate() {
    if (!this.isLoaded) {
      this.handleMenuData();
    }
  }

  render() {
    return (
      <Host>
        <ul class="menu-departments">
          {this.newMenuData &&
            this.newMenuData.map(department => (
              <li key={department.label}>
                <ptz-header-department-item
                  key={department.label}
                  label={department.label}
                  hasDrawer={department.hasDrawer}
                  isAnimated={department.isAnimated}
                  iconUrl={department.iconUrl}
                  href={department.href}
                  color={department.color}
                  categories={department.categories}
                  hasError={this.hasError}
                  analyticsTrackingHandler={this.analyticsTrackingHandler}
                ></ptz-header-department-item>
              </li>
            ))}
        </ul>
      </Host>
    );
  }
}
