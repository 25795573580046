import { EnumToArray } from '../../../../../utils/enumToArray';

enum Size {
  Small = 'sm',
  Large = 'lg',
}

export const PTZHeaderMenuBannerConfigList = {
  Size: EnumToArray([Size]),
};

export const PTZHeaderMenuBannerConfig = {
  Size,
};
