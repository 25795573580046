import PTZTokens from '@petz/ds-tokens/petz';
import { Component, Prop, Host, h, State, Listen } from '@stencil/core';
import { PTZHeaderTypes } from '../../types/ptz-header.types';
import { PTZHeaderConfig } from '../../types/ptz-header.enums';
import { isOnIframe } from '../../../../utils/utils';

@Component({
  tag: `ptz-header-profile`,
  styleUrl: `ptz-header-profile.scss`,
  shadow: false,
})
export class PTZHeaderProfile {
  /** Define a url base do ambiente */
  @Prop() baseUrl: string;
  /** Define o nome do usuário logado */
  @Prop() clientName: string;
  /** Define o id do cliente */
  @Prop() clientId: string;
  /** Valor do token do usuário */
  @Prop() clientToken: string = '';
  /** Método de traqueamento */
  @Prop() analyticsTrackingHandler: (type: PTZHeaderTypes.AnalyticsTrackingKind, options?: PTZHeaderTypes.AnalyticsTracking) => void;

  @State() matchMedia: MediaQueryList;

  @State() isMobile: boolean = window.matchMedia(`(max-width:${PTZTokens.breakpointXl})`).matches;

  @State() showDialog: boolean = false;

  private handleAnalyticsTracking(link_text: string, link_url: string) {
    this.analyticsTrackingHandler(PTZHeaderConfig.AnalyticsTrackingKind.HeaderLinkClicked, {
      link_text,
      link_url,
      department: null,
      category: null,
      subcategory: null
    });
  }

  private handleClickLogin = () => {
    const url = `${this.baseUrl}/entrar`;
    this.handleAnalyticsTracking('Entrar', url);
    if (isOnIframe() && window.parent && window.parent.location) {
      window.parent.location.href = url;
    } else {
      window.location.href = url;
    }
  };

  private handleClickLoginProfile = (isLogged: boolean) => {
    if (isLogged) {
      const url = `${this.baseUrl}/meu-cadastro/dashboard`;
      this.handleAnalyticsTracking('Meu Cadastro', url);

      if (isOnIframe() && window.parent && window.parent.location) {
        window.parent.location.href = url;
      } else {
        window.location.href = url;
      }
      return;
    }

    this.handleClickLogin();
  };

  private handleMatchMediaChange() {
    this.isMobile = this.matchMedia.matches;
  }

  @Listen("optionSelected")
  handleOptionProfileLinks(event: CustomEvent) {
    const target = event.target as HTMLElement;
    const url = target.dataset.url;
    const value = event.detail.value;
    this.handleAnalyticsTracking(value, url);
    window.location.href = url;
  }

  componentWillLoad() {
    this.matchMedia = window.matchMedia(`(max-width:${PTZTokens.breakpointXl})`);
    this.matchMedia.onchange = this.handleMatchMediaChange.bind(this);
  }

  disconnectedCallback() {
    this.matchMedia.onchange = null;
  }

  render() {
    const isLogged = !!this.clientId && !!this.clientName && !!this.clientToken;
    const ptzHeaderProfileLinks = [
      {
        title: 'Meus pedidos',
        url: `${this.baseUrl}/petz-pedidos`,
      },
      {
        title: 'Assinatura',
        url: `${this.baseUrl}/minhas-assinaturas`,
      },
      {
        title: 'Meus Pets',
        url: `${this.baseUrl}/list_PetLoja.html`,
      },
      {
        title: 'Meus Dados',
        url: `${this.baseUrl}/meu-cadastro`,
      },
      {
        title: 'Sair',
        url: `${this.baseUrl}/sair.html?logout=true`,
      },
    ];

    return (
      <Host>
        {this.isMobile ? (
          <button aria-label="perfil" class="ptz-header-profile" onClick={() => this.handleClickLoginProfile(isLogged)}>
            <ptz-icon name="petz-profile" variant="line" color="neutral-darker-accent" size="lg"></ptz-icon>
          </button>
        ) : (
            <div class="ptz-header-profile" onMouseOver={() => this.showDialog = true} onMouseOut={() => this.showDialog = false}>
              <ptz-icon name="petz-profile" variant="line" color="primary-brand" size="lg"></ptz-icon>
              <div class="ptz-header-profile-button">
                {isLogged ? (
                  <div class="ptz-header-profile-button-content">
                    <span class="ptz-header-profile-button-content-logged">Olá, {this.clientName}.</span>
                  </div>
                ) : (
                  <div class="ptz-header-profile-button-content">
                    <span class="ptz-header-profile-button-content-login">Entrar</span>
                    <span class="ptz-header-profile-button-content-register">Cadastre-se</span>
                  </div>
                )}
            </div>
            {this.showDialog && !isLogged && (
              <div class="ptz-header-profile-dialog">
                <ptz-button
                  id="loginButton"
                  button-aria-label="Botão"
                  label="Entrar"
                  disabled={false}
                  appearance="light"
                  size="lg"
                  kind="primary"
                  loading={false}
                  brand="ptz"
                  type="button"
                  onClick={() => this.handleClickLogin()}
                />
                <div>
                  <span class="ptz-header-profile-dialog-text">Não tem cadastro?</span>
                  <ptz-link
                    size="medium"
                    kind="default"
                    appearance="light"
                    underlined={false}
                    link-title="link para criar uma conta Petz"
                    onClick={() => this.handleAnalyticsTracking('Criar conta', `${this.baseUrl}/checkout/cadastro/home`)}
                    url={`${this.baseUrl}/checkout/cadastro/home`}
                    { ...(isOnIframe() ? { target: "_parent" } : {}) }
                  >
                    Criar conta
                  </ptz-link>
                </div>
              </div>
            )}
            {this.showDialog && isLogged && (
              <div class="ptz-header-profile-dialog ptz-header-profile-dialog-logged">
                {ptzHeaderProfileLinks.map(item => (
                  <ptz-dropdown-option key={item.title} data-url={item.url} value={item.title}></ptz-dropdown-option>
                ))}
              </div>
            )}
          </div>
        )}
      </Host>
    );
  }
}
