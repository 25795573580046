import { Component, Prop, Host, h } from '@stencil/core';
import { PTZHeaderIconNotificationTypes } from './types/ptz-header-icon-notification.types';
import { PTZHeaderTypes } from '../../types/ptz-header.types';
import { PTZHeaderConfig } from '../../types/ptz-header.enums';

@Component({
  tag: `ptz-header-icon-notification`,
  styleUrl: 'ptz-header-icon-notification.scss',
  shadow: false
})

export class PTZHeaderIconNotification {
  /** Quantidade de produtos na sacola */
  @Prop() amount: number;

  /** Tipo de componente do icone */
  @Prop() kind: PTZHeaderIconNotificationTypes.Kind;

  /** Url que o ícone redireciona */
  @Prop() iconUrlRedirect?: string  | null = null;

  /** Nome do ícone */
  @Prop() iconName: PTZHeaderIconNotificationTypes.IconName;

  /** Titulo para mostrar no hover do ícone */
  @Prop() iconTitle: string;

  /** Método de clique do botão */
  @Prop() clickHandler: () => void;

  /** Método de traqueamento */
  @Prop() analyticsTrackingHandler: (type: PTZHeaderTypes.AnalyticsTrackingKind, options?: PTZHeaderTypes.AnalyticsTracking) => void;

   private handleClick() {
    this.analyticsTrackingHandler(PTZHeaderConfig.AnalyticsTrackingKind.HeaderLinkClicked, {
      link_text: this.iconTitle,
      link_url: this.iconUrlRedirect,
      department: null,
      category: null,
      subcategory: null
    });

    this.clickHandler()
  }

  render() {
    return (
      <Host>
        <button class={`ptz-header-icon-notification ptz-header-icon-notification-${this.kind}`} onClick={() => this.handleClick()}>
          <ptz-icon-notification
            amount={this.amount}
            noticeColor='default'
            iconColor='neutral-black'
            iconName={this.iconName}
            iconVariant='line'
          />
          <span title={this.iconTitle} class='ptz-header-icon-notification-title'>{this.iconTitle}</span>
        </button>
      </Host>
    );
  }
}
