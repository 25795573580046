ptz-header-departments {
  width: 100%;
  display: none;

  .menu-departments {
    display: flex;
    list-style-type: none;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    background-color: $petz-color-neutral-white;
  }
}

@media screen and (min-width: $petz-breakpoint-xl) {
  ptz-header-departments {
    display: block;
  }
}
