ptz-header-icon-notification {
  .ptz-header-icon-notification {
    border: none;
    cursor: pointer;
    position: relative;
    transition: all 300ms ease-out;
    background-color: transparent;
    height: $petz-spacing-xl;
    padding: $petz-spacing-2xs;
    margin-right: $petz-spacing-2xs;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-title {
      opacity: 0;
      position: absolute;
      bottom: -1.25rem;
      font-weight: $petz-font-weight-regular;
      font-size: $petz-font-size-2xs;
      color: $petz-color-neutral-black;
      line-height: $petz-font-lineheight-lg;
      transition: opacity 300ms ease-out;
    }

    .ptz-notice-amount {
      line-height: 0;
    }

    &:hover {
      border-radius: $petz-rounded-max;
      background-color: rgba(red($petz-color-neutral-black), green($petz-color-neutral-black), blue($petz-color-neutral-black), $petz-opacity-xs);

      .ptz-header-icon-notification-title {
        opacity: 1;
      }
    }
  }
}

@media screen and (max-width: $petz-breakpoint-xl) {
  ptz-header-icon-notification {
    .ptz-header-icon-notification {
      position: absolute;
      top: $petz-spacing-xs;
      margin: 0;
      padding: 0;

      &-favorite {
        right: 5.125rem;
      }

      &-bag {
        right: 2.1875rem;
      }

      &:hover {
        border-radius: none;
        background-color: transparent;

        .ptz-header-icon-notification-title {
          opacity: 0;
        }
      }
    }
  }
}

@media screen and (max-width: $petz-breakpoint-sm) {
  ptz-header-icon-notification {
    .ptz-header-icon-notification {
      top: 1.25rem;

      &-favorite {
        right: 3.75rem;
      }

      &-bag {
        right: 1.125rem;
      }
    }
  }
}
