ptz-header-search {
  display: flex;
  z-index: 998;
  flex-grow: 1;

  .ptz-header-search {
    display: flex;
    flex-grow: 1;
    position: relative;
    margin-right: $petz-spacing-5xl;

    &.black-friday {
      ptz-card-tag {
        .ptz-card-tag i {
          margin-right: $petz-spacing-3xs;
        }

        span {
          font-size: $petz-font-size-4xs;

          .ptz-icon {
            font-size: $petz-font-size-3xs;
          }
        }
      }
    }

    &-overlay {
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      position: absolute;
      background-color: transparent;
    }

    ptz-text-field {
      display: flex;
      flex-grow: 1;

      ptz-input {
        flex-grow: 1;

        input {
          width: auto;
          border-radius: $petz-rounded-md;
          padding-left: 3.25rem;
          padding-top: $petz-spacing-2xs;
          padding-bottom: $petz-spacing-2xs;

          &.ptz-input-boxed-resting-done:not(:focus) {
            border-color: $petz-color-neutral-dark;
            color: $petz-color-neutral-dark;
          }
        }
      }

      ptz-header-error {
        padding: $petz-spacing-sm 0;
      }

      ptz-icon {
        left: 1.125rem;
        bottom: $petz-spacing-sm;

        i {
          color: $petz-color-neutral-dark;
        }
      }
    }

    #headerSearch + .ptz-input-icon {
      cursor: pointer;
    }

    &-dropdown {
      display: flex;
      width: 100%;
      flex-grow: 1;
      top: 3.75rem;
      position: absolute;
      flex-direction: column;
      align-items: flex-start;
      box-shadow: $petz-shadow-sm;
      border-radius: $petz-rounded-sm;
      background: $petz-color-neutral-white;
      overflow: hidden;

      &-error {
        padding: $petz-spacing-sm 0;
        align-items: center;
      }

      &-section {
        width: 100%;
      }

      &-item-title {
        display: block;
        box-sizing: border-box;
        background: $petz-color-neutral-lighter-accent;
        color: $petz-color-neutral-black;
        font-weight: $petz-font-weight-bold;
        font-size: $petz-font-size-2xs;
        padding: $petz-spacing-sm $petz-spacing-sm;
        line-height: $petz-font-lineheight-lg;
      }
    }

    &-product {
      &-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: $petz-spacing-sm;
        margin: $petz-spacing-sm;

        a {
          text-decoration: none;
          color: $petz-color-neutral-darker-accent;
        }

        ptz-card-tag {
          left: 50%;
          bottom: 0;
          position: absolute;
          transform: translate(-50%, 0);
        }
      }

      &-item {
        width: 100%;
        height: 10rem;
        box-sizing: border-box;
        padding: 0 $petz-spacing-sm;
        border-radius: $petz-rounded-xs;
        border: $petz-border-sm solid $petz-color-neutral-light;

        ptz-card {
          height: inherit;

          p {
            margin: 0;
          }

          & .ptz-card-product-horizontal .ptz-card-label-left {
            line-height: $petz-font-lineheight-lg;
            margin: $petz-spacing-xs 0 $petz-spacing-2xs;
            height: calc($petz-font-size-2xs * 2 * 1.32);
            max-height: unset;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          & .ptz-card-image {
            width: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $petz-breakpoint-xl) {
  ptz-header-search {
    width: 100%;

    .ptz-header-search {
      margin: $petz-spacing-xs 0 0;

      &-product {
        &-list {
          gap: 0;
          margin: 0;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-evenly;
          flex-direction: column;
        }

        &-item {
          width: 100%;
          height: 10.375rem;
          border-radius: unset;
          border: $petz-border-none;
          padding: 0 $petz-spacing-sm;
          border-bottom: $petz-border-sm solid $petz-color-neutral-light;
        }
      }
    }
  }
}

@media screen and (max-height: $petz-breakpoint-md) and (min-width: $petz-breakpoint-md) {
  ptz-header-search {
    .ptz-header-search-terms-and-history-recommended {
      overflow-y: auto;
      height: $petz-spacing-2xl * 2;
    }
  }
}

@media screen and (max-height: $petz-breakpoint-md) and (min-width: $petz-breakpoint-md) {
  ptz-header-search {
    .ptz-dropdown-option-a {
      padding: $petz-spacing-3xs $petz-spacing-sm;
    }
  }
}

@media screen and (max-width: $petz-breakpoint-md) {
  ptz-header-search {
    .ptz-header-search {
      margin-top: $petz-spacing-md;
    }
  }
}
