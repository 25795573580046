ptz-header-drawer-bag-empty {
  .ptz-header-drawer-bag-empty {
    &-content {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: $petz-spacing-2xl 0;
      text-align: center;

      &-icon {
        display: block;
        position: relative;
        margin: $petz-spacing-md;

        ptz-notice {
          right: 0.9rem;
          top: -0.625rem;
        }
      }

      &-title {
        margin: 0;
        font-weight: $petz-font-weight-bold;
        font-size: $petz-font-size-sm;
        line-height: $petz-font-lineheight-lg;
        color: $petz-color-neutral-darker-accent;
      }

      &-subtitle {
        margin: 0;
        font-weight: $petz-font-weight-regular;
        font-size: $petz-font-size-xs;
        line-height: $petz-font-lineheight-lg;
        color: $petz-color-neutral-darker-accent;
      }
    }

    &-footer {
      padding: $petz-spacing-lg;
    }
  }
}

@media screen and (max-width: $petz-breakpoint-md) {
  ptz-header-drawer-bag-empty {
    .ptz-header-drawer-bag-empty {
      .ptz-drawer-modal {
        height: 31.25rem !important;
      }

      &-footer {
        padding: $petz-spacing-sm $petz-spacing-md;
      }
    }
  }
}
