import { Component, Prop, Host, h } from '@stencil/core';
import { PTZDrawerConfig } from '../../../ptz-drawer/types/ptz-drawer.enums';

@Component({
  tag: 'ptz-header-drawer-bag-empty',
  styleUrl: 'ptz-header-drawer-bag-empty.scss',
  shadow: false
})

export class PTZHeaderDrawerBagEmpty {
  /** Mostrar ou não a gaveta */
  @Prop() open: boolean;

  /** Método de clique do botão do footer */
  @Prop() footerButtonClickHandler: () => void;

  /** A versão da tela é mobile ou desktop */
  @Prop() isMobile: boolean;

  render() {
    const orientation = this.isMobile ? PTZDrawerConfig.Orientation.Bottom : PTZDrawerConfig.Orientation.Right;

    return (
      <Host>
        <ptz-drawer class='ptz-header-drawer-bag-empty' dynamicContentHeight={false} rounded={true} orientation={orientation} loading={false} open={this.open}>
            <p slot='drawer-header-title'>Sacola</p>
            <ptz-icon slot='drawer-header-close' size='xl' variant='line' name='times' color='neutral-darker-accent'></ptz-icon>
            <div slot='drawer-content' class='ptz-header-drawer-bag-empty-content'>
              <p class='ptz-header-drawer-bag-empty-content-title'>Você ainda não tem</p>
              <p class='ptz-header-drawer-bag-empty-content-title'>produtos adicionados à sacola.</p>
              <div class='ptz-header-drawer-bag-empty-content-icon'>
                <ptz-icon name='petz-bag' variant='line' color='neutral-black' size='2xl'></ptz-icon>
                <ptz-notice amount={0} color='default' />
              </div>
              <p class='ptz-header-drawer-bag-empty-content-subtitle'>Escolha tudo que o seu pet precisa e adicione à<br/> sacola para comprar.</p>
            </div>
            <div slot='drawer-footer' class='ptz-header-drawer-bag-empty-footer'>
              <ptz-button
                size='lg-full-width'
                brand='ptz'
                id='button'
                kind='primary'
                label='Entendi'
                loading={false}
                disabled={false}
                appearance='light'
                onClick={() => this.footerButtonClickHandler()}
                button-aria-label='Fechar gaveta da sacola'
              />
            </div>
          </ptz-drawer>
      </Host>
    );
  }
}
