import { Component, Event, EventEmitter, Host, Listen, Prop, State, Watch, h } from '@stencil/core';
import { PTZHeaderConfig } from '../../types/ptz-header.enums';
import { PTZHeaderTypes } from '../../types/ptz-header.types';
import { PTZHeaderMenuDrawerTypes } from './types/ptz-header-menu-drawer.types';
import { isOnIframe, scrollToTop } from '../../../../utils/utils';

@Component({
  tag: `ptz-header-menu-drawer`,
  styleUrl: `ptz-header-menu-drawer.scss`,
  shadow: false,
})
export class PTZHeaderMenuDrawer {
  /** Determina se o drawer está aberto ou fechado */
  @Prop() open: boolean = false;
  /** Define a url base do ambiente */
  @Prop() baseUrl: string;
  /** Define o nome do usuário logado */
  @Prop() clientName: string;
  /** Define o id do cliente */
  @Prop() clientId: string;
  /** Valor do token do usuário */
  @Prop() clientToken: string = '';
  /** A versão da tela é mobile ou desktop */
  @Prop() isMobile: boolean;
  /** Define o conteúdo principal do menu do header */
  @Prop() mainMenuDepartments: PTZHeaderTypes.MenuDepartments[];
  /** Define o conteúdo do menu institucional */
  @Prop() institutionalDepartments: PTZHeaderTypes.MenuDepartments[] = [];
  /** Define o conteúdo do menu serviços */
  @Prop() servicesDepartments: PTZHeaderTypes.MenuDepartmentsCategories[];
  /** Define o conteúdo do menu promoções */
  @Prop() salesDepartments: PTZHeaderTypes.MenuDepartmentsCategories[];
  /** Retorna booleano caso o menu apresente erro */
  @Prop() hasMenuError: boolean;
  /** Método de traqueamento */
  @Prop() analyticsTrackingHandler: (type: PTZHeaderTypes.AnalyticsTrackingKind, options?: PTZHeaderTypes.AnalyticsTracking) => void;
  /** Guarda a lista inicial de itens do menu */
  @Prop() initialMenuDrawerItems: PTZHeaderMenuDrawerTypes.categoryWithSubcategories[];

  /** Guarda a lista de itens do menu */
  @State() menuDrawerItems: PTZHeaderMenuDrawerTypes.categoryWithSubcategories[];
  /** Guarda os links de login e logout */
  @State() authenticationLinks = {
    login: `entrar`,
    logout: `sair.html?logout=true`,
  };
  /** Guarda a lista anterior de departamento selecionada */
  @State() selectedCategories: PTZHeaderMenuDrawerTypes.categoryWithSubcategories[];
  /** Guarda a nova lista de categoria selecionada */
  @State() selectedSubCategories: PTZHeaderMenuDrawerTypes.categoryWithSubcategories[];
  /** Guarda o título anterior da gaveta */
  @State() selectedCategoryTitle?: string;
  /** Guarda o novo título da gaveta */
  @State() selectedSubCategoryTitle?: string;
  /** Guarda o link anterior da gaveta */
  @State() selectedCategoryHref?: string;
  /** Guarda o novo link da gaveta */
  @State() selectedSubCategoryHref?: string;
  /** Guarda os termos de itens que vão retornar erro caso clique em erro */
  @State() itemsWhoCallsErrorMessage = ['Serviços', 'Promoções', 'Institucional', 'Nossas Políticas', 'Aqui você encontra'];

  /** Evento para animação do drawer */
  @Event({
    eventName: 'slotDrawerChange',
    composed: true,
  })
  slotDrawerChange: EventEmitter<boolean>;

  @Listen('toggleDrawer')
  toggleDrawerHandler() {
    scrollToTop(this.scrollableContentSelector);
    setTimeout(() => {
      this.selectedSubCategoryTitle = '';
      this.selectedSubCategories = null;
      this.selectedSubCategoryHref = null;
    }, this.animationTime);
  }

  private animationTime = 300;

  private scrollableContentSelector = 'ptz-header-menu-drawer .ptz-drawer-modal-content';

  private addBaseUrl(url: string): string {
    if (url && !url.includes('http')) {
      return `${this.baseUrl}/${url}`;
    }
    return url;
  }

  private handleBackClick() {
    this.slotDrawerChange.emit(true);
    setTimeout(() => {
      if (this.selectedCategoryTitle === this.selectedSubCategoryTitle) {
        this.selectedSubCategoryTitle = '';
        this.selectedSubCategories = null;
        this.selectedSubCategoryHref = null;
      } else {
        this.selectedSubCategoryTitle = this.selectedCategoryTitle;
        this.selectedSubCategories = this.selectedCategories;
        this.selectedSubCategoryHref = this.selectedCategoryHref;
      }
      scrollToTop(this.scrollableContentSelector);
    }, this.animationTime);
  }

  private addProfileMenuDrawerItems() {
    const profileLinks = [
      {
        name: 'Meus pedidos',
        token: `${this.baseUrl}/petz-pedidos`,
      },
      {
        name: 'Assinatura',
        token: `${this.baseUrl}/minhas-assinaturas`,
      },
      {
        name: 'Meus pets',
        token: `${this.baseUrl}/list_PetLoja.html`,
      },
      {
        name: 'Meus dados',
        token: `${this.baseUrl}/meu-cadastro`,
      },
      {
        isSectionTitle: true,
        name: 'Petz para Você',
        token: '',
      },
      {
        name: 'Assinatura Petz',
        token: 'https://landingpage.petz.com.br/assinatura-petz/',
      },
      {
        name: 'Ajuda',
        token: `${this.baseUrl}/atendimento-inteligente`,
      },
    ];

    const initialMenuDrawerItemsForLoggedIn = this.initialMenuDrawerItems.slice(3)
    this.menuDrawerItems = [...profileLinks, ...initialMenuDrawerItemsForLoggedIn];
  }

  private handleMenuItemClick(title: string, href: string, category) {
    const hasSubcategories = category.subCategories && category.subCategories.length > 0;

    if (hasSubcategories || (this.hasMenuError && this.itemsWhoCallsErrorMessage.includes(title))) {
      this.slotDrawerChange.emit(true);
      setTimeout(() => {
        this.selectedCategoryTitle = this.selectedSubCategoryTitle;
        this.selectedSubCategoryTitle = title;
        this.selectedCategoryHref = this.selectedSubCategoryHref;
        this.selectedSubCategoryHref = href;
        this.selectedCategories = this.selectedSubCategories;
        this.selectedSubCategories = category.subCategories;
        scrollToTop(this.scrollableContentSelector);
      }, this.animationTime);
    }
  }

  private formatMenuItems(menuItems: PTZHeaderTypes.MenuDepartments[] | PTZHeaderTypes.MenuDepartmentsCategories[]) {
    if (menuItems) {
      return menuItems.map(el => ({
        name: el.name,
        token: el.token,
        isNewItem: false,
        subCategories: el.categories || el.subCategories,
        urlImage: el.urlImage ? el.urlImage : null,
        urlImageMobile: el.urlImageMobile ? el.urlImageMobile : null,
      }));
    }
    return null;
  }

  private onGetMenuData(title: string, categoryData) {
    const menuDepartmentsPosition = this.menuDrawerItems.findIndex(el => el.name === title);
    if (menuDepartmentsPosition !== -1) {
      const result = this.formatMenuItems(categoryData);
      this.menuDrawerItems[menuDepartmentsPosition].subCategories = result;
    }
  }

  private onGetMenuDataWithTitle(title: string, categoryData) {
    const menuDepartmentsPosition = this.menuDrawerItems.findIndex(el => el.name === title) + 1;
    const result = this.formatMenuItems(categoryData);
    this.menuDrawerItems.splice(menuDepartmentsPosition, 0, ...result);
  }

  private handleAnalyticsTracking(el: PTZHeaderMenuDrawerTypes.categoryWithSubcategories, isSaleBanners: boolean, isServiceBanners: boolean) {
    const props = {
      link_text: el.name,
      image_url: el.urlImage,
    };
    if (isSaleBanners) {
      this.analyticsTrackingHandler(PTZHeaderConfig.AnalyticsTrackingKind.PromotionClicked, {
        link_url: `${this.baseUrl}/${el.token}`,
        ...props,
      });
    }
    if (isServiceBanners) {
      this.analyticsTrackingHandler(PTZHeaderConfig.AnalyticsTrackingKind.EcosystemLinkClicked, {
        link_url: el.token,
        ...props,
      });
    }
    if (!isSaleBanners && !isServiceBanners) {
      const completeUrl = el.token.split('/').filter(Boolean);
      const department = completeUrl[2]?.replace(/-/g, ' ') || null;
      const category = completeUrl[3]?.replace(/-/g, ' ') || null;
      const subCategory = completeUrl[4]?.replace(/-/g, ' ');

      this.analyticsTrackingHandler(PTZHeaderConfig.AnalyticsTrackingKind.HeaderLinkClicked, {
        link_text: el.name,
        link_url: el.token,
        department,
        category,
        subcategory: subCategory || null,
      });
    }
  }

  @Watch('clientId')
  @Watch('clientName')
  @Watch('clientToken')
  @Watch('initialMenuDrawerItems')
  onLoggedIn() {
    const isLoggedIn = !!this.clientId && !!this.clientName && !!this.clientToken;
    if (isLoggedIn && this.initialMenuDrawerItems !== undefined) {
      this.addProfileMenuDrawerItems();
    }
  }

  @Watch('initialMenuDrawerItems')
  updateMenuDrawerItems() {
    if (this.menuDrawerItems === undefined) {
      this.menuDrawerItems = this.initialMenuDrawerItems;
    }
  }

  @Watch('mainMenuDepartments')
  onLoadMenuDepartments() {
    this.onGetMenuDataWithTitle('Departamentos', this.mainMenuDepartments);
  }

  @Watch('institutionalDepartments')
  onLoadInstitutionalDepartments() {
    this.onGetMenuDataWithTitle('Institucional', this.institutionalDepartments);
  }

  @Watch('servicesDepartments')
  onLoadServiceDepartments() {
    this.onGetMenuData('Serviços', this.servicesDepartments);
  }

  @Watch('salesDepartments')
  onLoadSaleDepartments() {
    this.onGetMenuData('Promoções', this.salesDepartments);
  }

  componentDidUpdate() {
    if (this.open && this.selectedSubCategoryTitle === 'Promoções') {
      this.salesDepartments.forEach(item => {
        this.analyticsTrackingHandler(PTZHeaderConfig.AnalyticsTrackingKind.PromotionViewed, {
          link_text: `${item.name}`,
          link_url: `${this.baseUrl}/${item.token}`,
          image_url: `${item.urlImage}`,
        });
      });
    }
  }

  render() {
    const isLoggedIn = !!this.clientId && !!this.clientName && !!this.clientToken;
    const isBanners = this.itemsWhoCallsErrorMessage.includes(this.selectedSubCategoryTitle);
    const isBannersWithError = isBanners && this.hasMenuError;
    const isServiceBanners = ['Serviços', 'Adestramento'].includes(this.selectedSubCategoryTitle);
    const isSaleBanners = this.selectedSubCategoryTitle === 'Promoções';
    const listClassesNameStyle = {
      'ptz-header-menu-drawer-list': true,
      'ptz-header-menu-drawer-list-grid': isServiceBanners && !this.hasMenuError,
    };
    const authentication = this.authenticationLinks;
    const content = this.selectedSubCategories || this.menuDrawerItems;
    const isFooterActive = isSaleBanners && !this.hasMenuError;
    const contentClassesNameStyle = { 'ptz-header-menu-drawer-content': !isFooterActive, 'ptz-header-menu-drawer-content-footer': isFooterActive }

    const handleHref = (subCategories, name, token?: string) => {
      const hasSubCategories = subCategories && subCategories.length > 0;
      const isBannerWithError = this.hasMenuError && this.itemsWhoCallsErrorMessage.includes(name);
      return hasSubCategories || isBannerWithError ? null : this.addBaseUrl(token);
    };

    const listTitle = (name: string) => (
      <div class="ptz-header-menu-drawer-list-title">
        {this.selectedSubCategoryTitle && (
          <button onClick={() => this.handleBackClick()}>
            <ptz-icon name="petz-chevron-left" brand="ptz" color="neutral-darker-accent" size="md" variant="line" />
          </button>
        )}
        <span>{name}</span>
      </div>
    );

    const menuHeader = () => (
      <div slot="drawer-header-title">
        <div class="ptz-header-menu-drawer-title">
          <ptz-icon size="lg" variant="line" name="petz-profile" color="neutral-darker-accent"></ptz-icon>
          {!isLoggedIn && <a class="ptz-header-menu-drawer-title-content" href={`${this.baseUrl}/${authentication.login}`}>Olá, faça seu login</a>}
          {isLoggedIn && (
            <p>
              <span>Olá, {this.clientName}</span>{' '}
              <a class="ptz-header-menu-drawer-title-highlight" href={`${this.baseUrl}/${authentication.logout}`}>
                (Sair)
              </a>
            </p>
          )}
        </div>
      </div>
    );

    const itemWideBanner = props => (
      <img class="ptz-header-menu-drawer-list-banner-image" src={this.isMobile ? props.urlImageMobile : props.urlImage} alt={props.name} />
    );

    const itemSmallBanner = props => (
      <ptz-header-menu-banner size="sm" label={props.name}>
        <img class="ptz-header-menu-drawer-list-banner-image" src={props.urlImageMobile} alt={props.name} />
      </ptz-header-menu-banner>
    );

    const menuItem = props => {
      switch (this.selectedSubCategoryTitle) {
        case 'Promoções':
          return itemWideBanner(props);
        case 'Serviços':
          return itemSmallBanner(props);
        case 'Adestramento':
          return itemSmallBanner(props);
        default:
          return <ptz-header-menu-drawer-item name={props.name} isNewItem={props.isNewItem} hasSubCategories={props.hasSubCategories} />;
      }
    };

    return (
      <Host>
        <ptz-drawer class="ptz-header-menu-drawer" open={this.open} orientation="left" dynamicContentHeight={true} rounded={true}>
          {menuHeader()}
          <ptz-icon slot="drawer-header-close" size="lg" variant="line" name="times" color="neutral-darker-accent"></ptz-icon>
          <div slot="drawer-content" class={contentClassesNameStyle}>
            <ul class={listClassesNameStyle}>
              {this.selectedSubCategoryTitle && (
                <li class={{ 'ptz-header-menu-drawer-list-title-grid': isServiceBanners && !this.hasMenuError }}>{listTitle(this.selectedSubCategoryTitle)}</li>
              )}
              {this.hasMenuError && isBanners && (
                <li>
                  <ptz-header-error show />
                </li>
              )}
              {!isBannersWithError &&
                content &&
                content.map(el => {
                  if (el.isSectionTitle) {
                    return <li key={el.name}>{listTitle(el.name)}</li>;
                  }
                  return (
                    <li
                      class={{
                        'ptz-header-menu-drawer-list-item': true,
                        'ptz-header-menu-drawer-list-banner': isSaleBanners,
                        'ptz-header-menu-drawer-list-banner-grid': isServiceBanners,
                      }}
                      key={el.name}
                      onClick={() => this.handleMenuItemClick(el.name, el.token, el)}
                    >
                      <a
                        href={handleHref(el.subCategories, el.name, el.token)}
                        onClick={() => this.handleAnalyticsTracking(el, isSaleBanners, isServiceBanners)}
                        target={isOnIframe() ? '_parent' : (isServiceBanners || el.token?.includes('landingpage') ? '_blank' : '_self')}
                      >
                        {menuItem({
                          name: el.name,
                          urlImage: el.urlImage,
                          urlImageMobile: el.urlImageMobile,
                          isNewItem: el.isNewItem,
                          hasSubCategories: (el.subCategories && el.subCategories.length > 0) || this.itemsWhoCallsErrorMessage.includes(el.name),
                        })}
                      </a>
                    </li>
                  );
                })}
            </ul>
          </div>
          {isFooterActive && (
            <div class="drawer-footer" slot="drawer-footer">
              <a
                onClick={() => {
                  this.analyticsTrackingHandler(PTZHeaderConfig.AnalyticsTrackingKind.HeaderLinkClicked, {
                    link_text: 'Ver todas as promoções',
                    link_url: `${this.selectedSubCategoryHref}`,
                    department: null,
                    category: null,
                    subcategory: null,
                  });
                }}
                href={this.selectedSubCategoryHref}
                target={isOnIframe() ? '_parent' : '_self'}
              >
                <ptz-button appearance="light" brand="ptz" button-aria-label="Ver todas as promoções" id="button" kind="primary" label="Ver todas as promoções" size="lg" />
              </a>
            </div>
          )}
        </ptz-drawer>
      </Host>
    );
  }
}
