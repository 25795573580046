import { Component, Event, EventEmitter, Host, h } from '@stencil/core';

@Component({
  tag: `ptz-header-hamburger`,
  styleUrl: `ptz-header-hamburger.scss`,
  shadow: false,
})
export class PTZHeaderHamburger{
  /** Evento que abre o drawer do menu ao clicar no hamburger */
  @Event({
    eventName: 'clickHandler',
    composed: true,
  }) clickHandler: EventEmitter<void>;

  private toggleHamburgerClickHandler() {
    this.clickHandler.emit();
  } 
  
  render() {    
    const handlerHamburgerClick = () => {
      this.toggleHamburgerClickHandler();
    }
    return (
      <Host>
        <button aria-label="menu" class='ptz-header-hamburger' onClick={handlerHamburgerClick}>
          <ptz-icon brand='ptz' name='petz-menu' variant='line' size='lg' />
        </button>
      </Host>
    );
  }
}
