@media screen and (min-width: $petz-breakpoint-md) {
ptz-header-profile {
  display: inline-block;
  z-index: 998;

  .ptz-header-profile {
    cursor: pointer;
    min-width: 6.313rem;
    width: max-content;
    height: $petz-spacing-2xl;
    display: flex;
    position: relative;
    left: 5.125rem;
    align-items: center;
    box-sizing: border-box;
    justify-content: space-between;
    border-radius: $petz-border-lg;
    margin-left: $petz-spacing-2xs;

    &-button {
      &-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-weight: $petz-font-weight-bold;
        line-height: $petz-font-lineheight-lg;
        color: $petz-color-primary-brand;

        ptz-icon {
          padding-right: $petz-spacing-2xs;
        }

        &-logged {
          font-size: $petz-font-size-2xs;
        }

        &-login {
          font-size: $petz-font-size-2xs;
        }

        &-register {
          font-size: $petz-font-size-3xs;
        }
      }
    }

    &-dialog {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      box-sizing: border-box;
      position: absolute;
      min-height: 8.813rem;
      width: 16.438rem;
      left: 100%;
      transform: translateX(-100%);
      top: calc(100% + $petz-spacing-2xs);
      box-shadow: $petz-shadow-md;
      background: $petz-color-neutral-white;
      padding: $petz-spacing-md;
      border-radius: $petz-border-none $petz-border-none $petz-rounded-md $petz-rounded-xs;

      &::before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: -0.813rem;
        height: $petz-spacing-md;
      }

      &-logged {
        padding: $petz-spacing-sm 0;
        box-sizing: border-box;

        ptz-dropdown-option {
          width: 100%;

          &:last-child {
            a {
              color: $petz-color-sup-red-darker-accent;
            }
          }
        }
      }

      &-text {
        font-weight: $petz-font-weight-regular;
        font-size: $petz-font-size-xs;
        padding-right: $petz-spacing-3xs;
        line-height: $petz-font-lineheight-lg;
        color: $petz-color-neutral-black;
      }

      ptz-button,
      ptz-button button {
        width: 100%;
      }
    }
  }
}
}

@media screen and (min-width: $petz-breakpoint-xl) {
  ptz-header-profile {
    .ptz-header-profile {
      left: inherit;
    }
  }
}

@media screen and (max-width: $petz-breakpoint-xl) {
  ptz-header-profile {
    .ptz-header-profile {
      margin: 0;
      padding: 0;
      border: none;
      position: absolute;
      left: 5.125rem;
      top: $petz-spacing-xs;
      height: $petz-spacing-xl;
      background-color: transparent;
    }
  }
}

@media screen and (max-width: $petz-breakpoint-sm) {
  ptz-header-profile {
    .ptz-header-profile {
      top: 1.25rem;
      left: 3.75rem;
    }
  }
}
