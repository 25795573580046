ptz-header-hamburger {
    align-items: center;
    display: flex;
    height: $petz-spacing-xl;
    justify-content: center;
    left: 1.125rem;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 1.25rem;

    .ptz-header-hamburger {
        border: none;
        background: none;
        cursor: pointer;
        padding: 0;
        color: inherit;
    }

    ptz-icon {
        display: flex;
    }
}

@media screen and (min-width: $petz-breakpoint-sm) {
    ptz-header-hamburger {
        top: $petz-spacing-xs;
        left: 2.1875rem;
    }
}

@media screen and (min-width: $petz-breakpoint-xl) {
    ptz-header-hamburger {
        display: none;
    }
}
