import { Component, Host, Prop, State, Watch, h } from '@stencil/core';
import { formatedPrice } from '../../../../utils/utils';
import { PTZButtonConfig } from '../../../ptz-button/types/ptz-button.enums';
import { PTZCounterConfig } from '../../../ptz-counter/types/ptz-counter.enums';
import { PTZCounterTypes } from '../../../ptz-counter/types/ptz-counter.types';
import { PTZDrawerConfig } from '../../../ptz-drawer/types/ptz-drawer.enums';
import { PTZHeaderConfig } from '../../types/ptz-header.enums';
import { PTZHeaderTypes } from '../../types/ptz-header.types';
import { PTZHeaderDrawerBagConfig } from './types/ptz-header-drawer-bag.enums';
import { PTZHeaderDrawerBagTypes } from './types/ptz-header-drawer-bag.types';

@Component({
  tag: 'ptz-header-drawer-bag',
  styleUrl: 'ptz-header-drawer-bag.scss',
  shadow: false,
})
export class PTZHeaderDrawerBag {
  /** Define a url base do ambiente */
  @Prop() baseUrl: string = '';

  /** Valor do id da sacola do usuário */
  @Prop() bagId: string = '';

  /** Define o tipo de gaveta ao abrir a sacola */
  @Prop() type: PTZHeaderDrawerBagTypes.Type;

  /** Deve mostrar ou não a gaveta */
  @Prop() open: boolean = false;

  /** A versão da tela é mobile ou desktop */
  @Prop() isMobile: boolean;

  /** Lista de produtos na sacola */
  @Prop() bagSummary: PTZHeaderDrawerBagTypes.BagSummary;

  /** Método de clique do botão do footer */
  @Prop() footerButtonClickHandler: () => void;

  /** Método que retorna a lista do resumo da sacola */
  @Prop() getSummaryListHandler: () => void;

  /** Método que atualiza a quantidade de produto na sacola */
  @Prop() updateProductHandler: (sku: string, quantity: number, typeError: PTZHeaderDrawerBagTypes.Error, product: PTZHeaderDrawerBagTypes.Item, position: number) => void;

  /** Método que deleta uma quantidade do produto da sacola */
  @Prop() deleteProductHandler: (sku: string, quantity: number, typeError: PTZHeaderDrawerBagTypes.Error, product: PTZHeaderDrawerBagTypes.Item, position: number) => void;

  /** Informação sobre possíveis erros */
  @Prop() hasError?: PTZHeaderDrawerBagTypes.BagError | null;

  /** Informação sobre o carregamento */
  @Prop() isLoading?: PTZHeaderDrawerBagTypes.BagLoad | null;

  /** Método que atualiza o loading da sacola */
  @Prop() setIsLoadingBag: (data: PTZHeaderDrawerBagTypes.BagLoad | null) => void;

  /** Método de traqueamento */
  @Prop() analyticsTrackingHandler: (type: PTZHeaderTypes.AnalyticsTrackingKind, options?: PTZHeaderTypes.AnalyticsTracking) => void;

  /** Sku do produto que o usuário deseja excluir */
  @State() trashedSkuProduct: string = '';

  private addProductTitle = 'Produto adicionado à sacola';

  private checkPurchaseTitle = 'Confira sua compra';

  private handleRemoveProduct(product: PTZHeaderDrawerBagTypes.Item, position: number, quantity: number, sku: string) {
    this.deleteProductHandler(sku, quantity, PTZHeaderDrawerBagConfig.Error.Delete, product, position);
  }

  private handleQuantityChange = (event: CustomEvent<PTZCounterTypes.CounterEvent>, product: PTZHeaderDrawerBagTypes.Item, position: number, sku: string) => {
    const { quantity, mode } = event.detail;
    if (mode === PTZCounterConfig.Mode.Increment) {
      this.updateProductHandler(sku, quantity, PTZHeaderDrawerBagConfig.Error.Increment, product, position);
    }
    if (mode === PTZCounterConfig.Mode.Decrement) {
      this.deleteProductHandler(sku, quantity, PTZHeaderDrawerBagConfig.Error.Decrement, product, position);
    }
    this.trashedSkuProduct = '';
  };

  private handleProductRedirect = (event: CustomEvent<{product: PTZHeaderDrawerBagTypes.Item, position: number}>) => {
    const { product, position } = event.detail;
    const url = `${this.baseUrl}/${product.token}`;
    this.analyticsTrackingHandler(PTZHeaderConfig.AnalyticsTrackingKind.ProductClicked, {
      element: 'Header Sacola',
      cart_id: this.bagId,
      image_url: `${product.thumbnail}`,
      product_category: `${product.category}`,
      product_discount: `${product.previousPrice}` || null,
      product_id: `${product.id}`,
      product_name: `${product.name}`,
      product_price: `${product.currentPrice}`,
      product_url: `${url}`,
      position: `${position}`,
    });

    window.location.href = `${url}`;
  }

  handleCheckoutRedirect() {
    const url = `${this.baseUrl}/checkout/cart/${this.bagId}`;
    this.analyticsTrackingHandler(PTZHeaderConfig.AnalyticsTrackingKind.HeaderLinkClicked, {
      link_text: 'Ir para sacola',
      link_url: url,
      department: null,
      category: null,
      subcategory: null
    });

    window.location.href = url;
  }

  private handleTrashIcon(sku: string) {
    if (this.trashedSkuProduct === sku) {
      this.trashedSkuProduct = '';
    } else {
      this.trashedSkuProduct = sku;
    }
  }

  @Watch('open')
  watchOpenProp(newValue: boolean) {
    if (newValue) {
      this.setIsLoadingBag({ type: PTZHeaderDrawerBagConfig.Load.Get });
      return;
    }
    this.trashedSkuProduct = '';
  }

  @Watch('isLoading')
  watchLoadProp(_, oldValue: PTZHeaderDrawerBagTypes.BagLoad | null) {
    const isDeleteLoad = oldValue?.type === PTZHeaderDrawerBagConfig.Load.GetDelete;
    const hasNoItems = this.bagSummary?.items.length === 0;

    if (isDeleteLoad && hasNoItems) {
      this.footerButtonClickHandler();
    }
  }

  componentDidUpdate() {
    const { open, isLoading } = this;
    const isLoadingGet = isLoading?.type === PTZHeaderDrawerBagConfig.Load.Get;

    if (open && isLoadingGet) {
      this.getSummaryListHandler && this.getSummaryListHandler();
    }
  }

  render() {
    const { items, subTotal } = this.bagSummary ?? {};
    const title = this.type === PTZHeaderDrawerBagConfig.Type.Added ? this.addProductTitle : this.checkPurchaseTitle;
    const orientation = this.isMobile ? PTZDrawerConfig.Orientation.Bottom : PTZDrawerConfig.Orientation.Right;
    const hasGetItemsWithError = this.hasError && this.hasError.type === PTZHeaderDrawerBagConfig.Error.Get;
    const buttonFooterSize = this.isMobile ? PTZButtonConfig.Size.MediumFullWidth : PTZButtonConfig.Size.LargeFullWidth;
    const subTotalPrice = this.isLoading?.type === PTZHeaderDrawerBagConfig.Load.Get ? formatedPrice(0) : formatedPrice(subTotal);

    return (
      <Host>
        <ptz-drawer class="ptz-header-drawer-bag" open={this.open} dynamicContentHeight={true} rounded={true} orientation={orientation} loading={!!this.isLoading}>
          <p slot="drawer-header-title">{title}</p>
          <ptz-icon slot="drawer-header-close" size="xl" variant="line" name="times" color="neutral-darker-accent"></ptz-icon>
          {hasGetItemsWithError && (
            <div slot="drawer-content" class="ptz-header-drawer-bag-content">
              <div class="ptz-header-drawer-bag-content-error">
                <ptz-header-error show />
              </div>
            </div>
          )}
          {!hasGetItemsWithError && <div slot="drawer-content" class="ptz-header-drawer-bag-content">
            {items?.map((product, index) => (
              <ptz-header-drawer-bag-card
                key={product.id}
                isAvailable={product.availability}
                productPosition={index+1}
                hasError={this.hasError}
                isLoading={this.isLoading}
                bagProductDetails={product}
                trashedSkuProduct={this.trashedSkuProduct}
                trashIconHandler={this.handleTrashIcon.bind(this)}
                removeProductHandler={this.handleRemoveProduct.bind(this)}
                quantityChangeHandler={this.handleQuantityChange.bind(this)}
                onProductRedirectHandler={(event) => this.handleProductRedirect(event)}
              />
            ))}
          </div>}
          {!hasGetItemsWithError && <div slot="drawer-footer" class="ptz-header-drawer-bag-footer">
            <div class="ptz-header-drawer-bag-footer-subtotal">
              <p class="ptz-header-drawer-bag-footer-subtotal-title">Subtotal</p>
              <span class="ptz-header-drawer-bag-footer-subtotal-price">{subTotalPrice}</span>
            </div>
            <ptz-button
              brand="ptz"
              id="goToCheckoutButton"
              kind="primary"
              loading={false}
              disabled={false}
              appearance="light"
              size={buttonFooterSize}
              label="Ir para sacola"
              button-aria-label="Ir para sacola"
              onClick={() => this.handleCheckoutRedirect()}
            />
            <ptz-button
              brand="ptz"
              id="closeBagButton"
              kind="ghost"
              label="Voltar"
              loading={false}
              disabled={false}
              appearance="light"
              size={buttonFooterSize}
              onClick={() => this.footerButtonClickHandler()}
              button-aria-label="Fechar a gaveta do resumo da sacola"
            />
          </div>}
        </ptz-drawer>
      </Host>
    );
  }
}
